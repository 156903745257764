import { useTheme } from '@mui/material';
import type { SVGProps } from 'react';

export const InsuraceLogo = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  const insurColor =
    theme.palette.mode === 'light' ? '#1C4332' : theme.palette.common.white;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="20"
      fill="none"
      {...props}
    >
      <path
        fill={insurColor}
        fillRule="evenodd"
        d="M42.887 3.894a2.104 2.104 0 0 0-1.304.405 1.259 1.259 0 0 0-.544 1.046c0 .683.509 1.15 1.527 1.401.483.12.817.236 1 .345.184.11.276.234.276.373a.36.36 0 0 1-.227.331 1.25 1.25 0 0 1-.568.115c-.66 0-1.292-.25-1.896-.753V8.23c.473.418 1.082.637 1.826.656h.055c.46.01.912-.12 1.297-.37a1.19 1.19 0 0 0 .558-1.053c0-.734-.51-1.229-1.527-1.485a.532.532 0 0 1-.084-.02l-.097-.022a5.483 5.483 0 0 1-.826-.258c-.18-.079-.269-.192-.269-.341a.38.38 0 0 1 .23-.341c.178-.09.374-.133.572-.126.303.006.602.061.886.164a4 4 0 0 1 .906.443V4.41a3.55 3.55 0 0 0-1.791-.516Zm-11.621.056h1.052v4.88h-1.052V3.95Zm5.014 0 1.638 3.876V3.95h1.025v4.88h-1.625l-1.687-4.002V8.83h-1.025V3.95h1.674Zm10.56.007h1.053v2.9c-.007.28.102.55.3.746a.972.972 0 0 0 .711.3.982.982 0 0 0 .715-.3c.2-.196.31-.466.303-.746v-2.9h1.046v2.9c.013.543-.2 1.067-.59 1.447a2.158 2.158 0 0 1-2.941.007 1.934 1.934 0 0 1-.596-1.454v-2.9Zm9.283 1.653a.714.714 0 0 1-.718.71h-1.179V4.891h1.178a.7.7 0 0 1 .51.206.68.68 0 0 1 .209.505v.008ZM53.18 3.95v4.88h1.045V7.226h.823l.969 1.604h1.185l-1.046-1.736c.29-.114.538-.316.708-.578.176-.26.27-.565.268-.879a1.617 1.617 0 0 0-.505-1.185 1.673 1.673 0 0 0-1.224-.502h-2.223Z"
        clipRule="evenodd"
      />
      <path
        fill="#28DA98"
        fillRule="evenodd"
        d="M32.366 13.05a1.27 1.27 0 0 0 .37.914c.238.252.57.391.917.383a1.317 1.317 0 0 0 1.296-1.296 1.267 1.267 0 0 0-.383-.913 1.233 1.233 0 0 0-.913-.393 1.216 1.216 0 0 0-.918.388c-.24.245-.373.575-.37.918Zm-.53-1.78a2.274 2.274 0 0 1 1.69-.721c.49-.01.966.166 1.332.493v-.411h1.324v4.838h-1.324v-.401c-.37.318-.845.488-1.333.475a2.252 2.252 0 0 1-1.684-.74 2.45 2.45 0 0 1-.708-1.752 2.471 2.471 0 0 1 .704-1.78v-.001Zm7.093 0c.461-.47 1.095-.73 1.753-.721.5-.006.99.148 1.397.438.408.284.72.685.895 1.15l-1.205.347a1.274 1.274 0 0 0-.434-.543 1.095 1.095 0 0 0-.652-.206 1.174 1.174 0 0 0-.895.393 1.31 1.31 0 0 0-.365.922c-.005.342.127.672.365.918.228.252.554.393.895.388.235.004.466-.068.657-.206a1.3 1.3 0 0 0 .429-.534l1.205.347c-.176.46-.486.858-.89 1.141a2.36 2.36 0 0 1-1.401.439 2.388 2.388 0 0 1-1.767-.736 2.501 2.501 0 0 1 .014-3.537Zm9.495 1.269h-2.346a1.16 1.16 0 0 1 .425-.585c.217-.16.482-.244.753-.237a1.195 1.195 0 0 1 1.168.822Zm.411-1.424a2.477 2.477 0 0 0-3.323.155 2.501 2.501 0 0 0-.013 3.537c.46.48 1.1.747 1.766.735a2.89 2.89 0 0 0 1.351-.342 2.08 2.08 0 0 0 .94-.908l-1.15-.33c-.313.28-.722.428-1.141.412a1.327 1.327 0 0 1-.758-.215 1.043 1.043 0 0 1-.429-.616h3.633c.037-.156.056-.315.055-.475a2.338 2.338 0 0 0-.064-.53 2.467 2.467 0 0 0-.867-1.423Z"
        clipRule="evenodd"
      />
      <path
        fill="#28DA98"
        d="M16.951 10a2.139 2.139 0 0 0-2.139-2.139l1.908-1.907c0 1.18.958 2.139 2.139 2.139L16.95 10ZM12.905 5.954a2.139 2.139 0 0 0-2.14-2.139l1.908-1.907c0 1.18.958 2.138 2.139 2.138l-1.907 1.908Z"
      />
      <path
        fill={insurColor}
        d="M16.951 18.093a2.139 2.139 0 0 0-2.138-2.14l1.907-1.907c0 1.181.958 2.139 2.139 2.139l-1.908 1.908ZM12.905 14.046a2.139 2.139 0 0 0-2.14-2.138L12.674 10c0 1.181.958 2.139 2.139 2.139l-1.907 1.907ZM16.938 2.147c.008-.078.013-.158.013-.24l-.014.24Z"
      />
      <path
        fill="#28DA98"
        d="M20.207 8.651a1.907 1.907 0 0 0-2.698 0 1.901 1.901 0 0 0-.558 1.35c-.001 1.181-.958 2.138-2.14 2.138h.001c-.488 0-.976.186-1.349.559a1.9 1.9 0 0 0-.558 1.35c-.001 1.18-.957 2.135-2.136 2.137h-.004a2.139 2.139 0 0 1-2.138-2.138c0-.489-.186-.977-.559-1.35a1.901 1.901 0 0 0-1.349-.558 2.139 2.139 0 0 1-2.138-2.137v-.004c0-1.18.957-2.136 2.137-2.137.488 0 .977-.185 1.35-.558.373-.373.559-.861.559-1.35 0-1.18.958-2.138 2.139-2.138a1.907 1.907 0 1 0-1.907-1.907A2.139 2.139 0 0 1 6.72 4.046c-.488 0-.977.186-1.35.56-.372.372-.558.86-.558 1.348a2.139 2.139 0 0 1-2.138 2.139 1.907 1.907 0 1 0 .001 3.815 2.139 2.139 0 0 1 2.137 2.136c0 .489.185.978.559 1.351a1.9 1.9 0 0 0 1.354.559 2.139 2.139 0 0 1 2.133 2.139 1.908 1.908 0 0 0 3.815 0c0-1.182.958-2.14 2.139-2.14.488 0 .976-.185 1.349-.558.374-.374.56-.864.558-1.354a2.139 2.139 0 0 1 2.137-2.133 1.908 1.908 0 0 0 1.35-3.256Z"
      />
      <path
        fill={insurColor}
        d="M28.3 8.651a1.901 1.901 0 0 0-1.348-.558 2.139 2.139 0 0 1-2.139-2.139 1.9 1.9 0 0 0-.559-1.349 1.901 1.901 0 0 0-1.349-.559 2.139 2.139 0 0 1-2.138-2.135 1.907 1.907 0 1 0-3.815-.003c0 .082-.005.164-.014.244v-.005a2.13 2.13 0 0 1-.999 1.579l-.002.001a2.112 2.112 0 0 1-.16.09l-.012.006a2.11 2.11 0 0 1-.163.072l-.018.008c-.055.02-.11.04-.167.056l-.02.006c-.057.016-.115.03-.174.042l-.02.003c-.06.011-.12.02-.182.026l-.013.001c-.064.006-.129.01-.194.01-.06 0-.118.002-.176.008h-.007a1.909 1.909 0 0 0-.526.128l-.01.003a1.92 1.92 0 0 0-.166.077l-.003.002c-.046.024-.09.051-.135.08l-.027.016c-.045.029-.087.061-.13.095-.008.006-.017.011-.025.018a1.912 1.912 0 0 0-.39.43l-.046.08c-.016.027-.034.054-.05.083-.011.023-.02.047-.032.07-.015.033-.032.066-.046.1-.01.025-.018.053-.028.08-.01.03-.023.062-.033.094-.01.036-.018.073-.027.11-.005.023-.012.046-.016.068a1.9 1.9 0 0 0-.02.137l-.007.045a1.935 1.935 0 0 0-.007.154l-.001.029a2.139 2.139 0 0 1-2.138 2.139A1.907 1.907 0 1 0 12.675 10c0-1.18.956-2.138 2.136-2.139.243 0 .485-.045.713-.137l.006-.002c.052-.02.102-.045.152-.07l.018-.009c.045-.023.089-.05.132-.078l.03-.018c.042-.027.083-.058.123-.09l.032-.023a1.953 1.953 0 0 0 .276-.277c.01-.01.017-.023.026-.034.03-.04.06-.08.088-.12l.017-.03c.028-.044.055-.088.079-.133l.009-.02c.025-.049.049-.1.07-.15l.003-.01c.09-.227.136-.468.136-.71l.013-.236a2.139 2.139 0 0 1 4.264.237c0 .49.186.978.559 1.352.373.373.862.559 1.35.558a2.139 2.139 0 0 1-.001 4.278c-.488 0-.977.186-1.35.559-.372.373-.558.862-.558 1.35a2.139 2.139 0 0 1-2.136 2.137 1.908 1.908 0 0 0-1.352 3.256 1.908 1.908 0 0 0 3.257-1.349c0-1.18.958-2.138 2.139-2.138.488 0 .976-.186 1.349-.559.372-.372.558-.86.558-1.35 0-1.18.957-2.136 2.137-2.137a1.907 1.907 0 0 0 1.35-3.256Z"
      />
    </svg>
  );
};
