import type { FC, SVGProps } from 'react';

export const NextLogo: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} {...props}>
    <path
      d="M17.754.012c-.078.008-.34.031-.574.05C11.78.547 6.727 3.462 3.523 7.938a18.795 18.795 0 0 0-3.351 8.301C.02 17.281 0 17.59 0 19.004s.02 1.723.172 2.766c1.031 7.136 6.11 13.128 12.996 15.351 1.234.399 2.535.668 4.012.832.574.063 3.066.063 3.64 0 2.551-.281 4.715-.914 6.848-2.004.324-.164.387-.21.344-.246-.032-.023-1.422-1.89-3.094-4.148l-3.04-4.106-3.808-5.633c-2.093-3.097-3.816-5.632-3.832-5.632-.015-.004-.031 2.5-.039 5.558-.008 5.352-.011 5.567-.082 5.692-.094.183-.168.257-.324.34-.121.058-.223.07-.785.07h-.64l-.173-.11a.686.686 0 0 1-.25-.27l-.078-.167.008-7.445.012-7.454.113-.144c.063-.078.188-.176.277-.227.153-.074.211-.082.856-.082.758 0 .883.032 1.078.246.059.059 2.117 3.164 4.586 6.907 2.465 3.738 5.84 8.847 7.496 11.351l3.008 4.559.152-.102c1.348-.875 2.774-2.125 3.902-3.422a18.894 18.894 0 0 0 4.473-9.714c.152-1.043.172-1.352.172-2.766s-.02-1.723-.172-2.766C36.797 9.102 31.718 3.11 24.832.887 23.617.492 22.324.223 20.875.059c-.355-.04-2.813-.079-3.121-.047ZM25.535 11.5c.176.09.32.262.375.441.027.094.035 2.16.027 6.813l-.011 6.68-1.176-1.805-1.18-1.805V16.97c0-3.137.012-4.903.035-4.989a.77.77 0 0 1 .368-.468c.152-.078.21-.086.793-.086.55 0 .644.008.77.074Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#000',
        fillOpacity: 1,
      }}
    />
  </svg>
);
